<template>
    <div class="cloud-import">
        <CloudImportLog
            v-if="validatedLogs.length"
            :rows="validatedLogs"
            :isStaticSummary="true"
            :summary="summary"
            :mode="mode"
            @onLazyload="onLazyload"
            @onChangeMode="onChangeMode"
        />
    </div>
</template>

<script>
import CloudImportLog from "@/components/CloudImport/CloudImportLog.vue";
import ServiceCloudImport from "@/services/ServicesCloudImport/ServiceCloudImport";
export default {
	name: "PageCloudImportLog",
	components: {
        CloudImportLog
	},
    /**
     * Входные данные компонента
     */
    props: {
        taskId: {
            type: Number,
            default: 0
        }
    },
	data() {
		return {
            validatedLogs: [],
            summary: {},
            page: null,
            pageCount: null,
            mode: 'uploaded'
		}
	},
	computed: {

	},
    beforeMount() {
        this.page = 1;
    },
    created() {

	},
	methods: {
        onLazyload(){
            if(this.page < this.pageCount){
                this.page++;
            }
        },

        onChangeMode(mode){
            this.mode = mode;
            this.page = null;
        }
	},
	watch: {
        page() {
            if(this.page === null){
                this.rows = [];
                this.page = 1;
                return;
            }

            ServiceCloudImport.logSideList(this.taskId, this.mode, this.page, {}, (result) => {
                const {pageCount = 0, items = [], summary = {}} = result;
                this.validatedLogs = [...this.validatedLogs, ...items];
                this.pageCount = pageCount;
                this.summary = summary;
                }, () => {}
            );
        }
	}
}
</script>

<style lang="scss" scoped>
.cloud-import {
    overflow-y: auto;
    padding: 20px;
}
</style>
